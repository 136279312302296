import Vue from "vue";
import Vuex from "vuex";
import { state } from "./state";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { checks } from "@/store/modules/checks";
import { calcState } from "@/store/modules/calculator/state";
import { calcMutations } from "@/store/modules/calculator/mutations";
import calcActions from "@/store/modules/calculator/actions";
import { calcGetters } from "@/store/modules/calculator/getters";
import { common } from "@/store/modules/common";
import { statistic } from "@/store/modules/statistic";
Vue.use(Vuex);
const calculator = {
    namespaced: true,
    state: {
        ...calcState,
    },
    mutations: {
        ...calcMutations,
    },
    actions: {
        ...calcActions,
    },
    getters: {
        ...calcGetters,
    },
};
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    modules: {
        calculator,
        checks,
        common,
        statistic,
    },
});
