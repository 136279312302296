export const mutations = {
    loginUser(state, data) {
        state.userLogged = true;
        state.user.id = data.id;
        state.user.name = data.name;
        state.user.surname = data.surname;
        state.user.patronymic = data.patronymic ?? "";
        state.user.bonuses = data.bonuses;
        state.user.photo = data.photo;
        state.user.chief = data.chief;
        state.user.participantCount = data.participantCount;
        state.user.alerts = data.alerts;
        state.user.account = data.account;
    },
    logoutUser(state) {
        state.userLogged = false;
        state.user.id = null;
        state.user.name = "";
        state.user.surname = "";
        state.user.patronymic = "";
        state.user.bonuses = 0;
        state.user.photo = "";
        state.user.chief = 0;
        state.user.participantCount = 0;
        state.user.alerts = {
            news: {
                date: 0,
                flag: false,
            },
        };
        state.user.account = null;
    },
    updateRegisteredEvent(state) {
        let currentDate = new Date();
        /** Если событие уже порошло, то его удаляем **/
        state.user.registeredEvent = state.user.registeredEvent.filter((el) => new Date(el.date) > currentDate);
    },
    setRegisteredEvent(state, data) {
        state.user.registeredEvent = [...state.user.registeredEvent, data];
    },
    writeAvailableData(state, data) {
        state.availableTimer.time = data.time;
        state.availableTimer.timer = data.timer;
        state.availableTimer.message_free = data.message_free;
        state.availableTimer.message_payed = data.message_payed;
        state.availableTimer.minutes = data.minutes;
    },
    timerCountdown(state) {
        state.availableTimer.minutes = state.availableTimer.minutes - 1;
    },
    writeProfileInfo(state, data) {
        state.profileInfo = data;
    },
    writeEditableProfileInfo(state, data) {
        state.editableProfileInfo.photo_file = data.profile.photo;
        state.editableProfileInfo.name = data.profile.name;
        state.editableProfileInfo.hide_reward_user =
            !!data.profile.hide_reward_user;
        state.editableProfileInfo.hide_preferences_user =
            !!data.profile.hide_preferences_user;
    },
    writeSpecialisations(state, data) {
        state.specialisations = data;
    },
    toggleCommission(state, bool) {
        state.editableProfileInfo.hide_reward_user = bool;
        state.profileInfo.profile.hide_reward_user = bool;
    },
    togglePreferences(state, bool) {
        state.editableProfileInfo.hide_preferences_user = bool;
        state.profileInfo.profile.hide_preferences_user = bool;
    },
    updateNewsAlert(state) {
        state.user.alerts.news = {
            date: Date.now(),
            flag: false,
        };
    },
    toggleFirstUserRequestLoading(state, bool) {
        state.firstUserRequestLoading = bool;
    },
};
