export class Parameter {
  /**
   * @param {object|Parameter} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.techName = data["tech_name"] || data.techName;
    this.categoryId = data["category_id"] || data.categoryId;
    this.categoryName = data["category_name"] || data.categoryName;
    this.valueType = data["value_type"] || data.valueType;
    this.required = data.required;
    this.multiple = data.multiple;
    this.question = data.question;
    this.alarm = data.alarm;
    this.showPercent = data.showPercent;
    this.impactsFinder = data["parameterImpactsFinder"] || data.impactsFinder;
    this.min = data.min;
    this.max = data.max;
    this.hidden = data.hidden || false;
    this.values = data.values.map((value) => new OptionValue(value));
  }
}

class OptionValue {
  /**
   * @param {object|OptionValue} data
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.hidden = data.hidden || false;
  }
}

export const parameterTypes = {
  ARRAY: "array",
  BOOLEAN: "boolean",
  NUMBER: "number",
  STRING: "string",
  INTEGER: "integer",
};
