<template>
  <v-autocomplete
    class="border-radius"
    dense
    outlined
    :value="value"
    :label="label"
    :height="height"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :rules="rules"
    :hide-details="hideDetails"
    :error-messages="errorMessage"
    :disabled="disabled"
    background-color="#fff"
    @input="updateValue($event)"
    @blur="validate"
    :key="inputKey"
  />
</template>

<script>
import Vue from "vue";

/**
 * Компонент select поля
 */
export default Vue.extend({
  name: "SelectInput",
  data() {
    return {
      inputKey: 0,
    };
  },
  props: {
    /**
     * Значение поля, обязательные параметр
     */
    value: {
      required: true,
    },
    /**
     * label поля
     */
    label: {
      type: String,
    },
    /**
     * Высота поля
     */
    height: {
      type: String,
      default: "42",
    },
    /**
     * Массив значений поля, сами значения должны быть объектами
     */
    items: {
      type: Array,
    },
    /**
     * Отображаемое значение поля, сюда передается ключ объекта с названием поля
     */
    itemText: {
      type: String,
    },
    /**
     * Значение поля, сюда передается ключ объекта со значением поля
     */
    itemValue: {
      type: String,
    },
    /**
     * Сообщение с ошибкой
     */
    errorMessage: {
      type: String,
    },
    rules: {
      type: Array,
    },
    hideDetails: Boolean,
    disabled: Boolean,
  },

  methods: {
    /**
     * Обновляет значение поля
     * @param val актуальное значение
     */
    updateValue(val) {
      if (val !== null) {
        this.$emit("input", val);
      }
    },
    validate() {
      this.inputKey++;
    },
  },
});
</script>
