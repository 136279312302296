export const getDefaultAccountState = () => {
    return {
        participants: [],
        participantsLoading: false,
        contactsBank: [],
        bankAgreements: [],
        myCommissions: [],
        sections: [],
        modules: {
            participants: false,
            contactsBank: false,
            bankAgreements: false,
            myCommissions: false,
        },
    };
};
export const state = getDefaultAccountState();
