import api from "@/shared/services/api";
export const commonActions = {
    openAlertError({ commit }, e, errorMessageProp = "Внутренняя ошибка сервера") {
        /** Ошибка валидации не обрабатывается */
        if (e?.response?.data?.validationErrors?.length) {
            return;
        }
        const errorMessage = e?.response?.data?.errorMessage ?? errorMessageProp;
        commit("calculator/openAlert", errorMessage, { root: true });
    },
    openInfoModal({ commit }, data) {
        let dataForModal;
        if (data.status === "success") {
            dataForModal = {
                text: data.successText ?? "Успешно",
                type: "success",
            };
        }
        else if (data.isValidationErrors) {
            dataForModal = {
                text: "Данные заполнены некорректно",
                type: "error",
            };
        }
        else {
            dataForModal = {
                text: data.errorMessage,
                type: "error",
            };
        }
        commit("openInfoModal", dataForModal);
    },
    logTransition(_, payload) {
        return api.post(process.env.VUE_APP_LOG_TRANSITION, payload);
    },
    saveFile({ dispatch }, payload) {
        const linkSource = `data:application/pdf;base64,${payload.content}`;
        fetch(linkSource)
            .then((res) => res.blob())
            .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = payload.fileName;
            a.click();
        });
        let dataForModal = {
            status: "success",
            successText: payload.successText ?? "Документы скачаны",
            isValidationErrors: false,
        };
        dispatch("openInfoModal", dataForModal);
    },
    getBanksList({ state }) {
        return api.get(process.env.VUE_APP_BANKS_LIST).then((res) => {
            state["banksList"] = res.data.data;
        });
    },
};
