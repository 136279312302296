export const getters = {
    getRegionsForRepresentative(state) {
        let updateRegions = state.regions;
        updateRegions = updateRegions.slice(1);
        updateRegions[0] = { name: "Выбрать все", id: -1 };
        return updateRegions;
    },
    getRegionsWithoutFirstItem(state) {
        let regions = state.regions;
        regions = regions.slice(2);
        return regions;
    },
};
