export default class NewsType {
    type;
    name;
    newsData;
    /** Конструктор объекта типа новости
     * @constructor
     * @param data данные для конструктора
     */
    constructor(data) {
        /** Тип новости */
        this.type = data.type;
        /** Имя типа новости */
        this.name = data.name;
        /** Объект с новостям информацией */
        this.newsData = {};
    }
}
