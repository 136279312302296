import Vue from "vue";
import SelectInput from "@/calculator/components/CommonComponents/Inputs/SelectInput.vue";
import MainBtn from "@/calculator/components/CommonComponents/MainBtn/MainBtn.vue";
import BtnWithShadow from "@/calculator/components/CommonComponents/MainBtn/BtnWithShadow.vue";
import TextInput from "@/calculator/components/CommonComponents/Inputs/TextInput.vue";
import SliderInput from "@/calculator/components/CommonComponents/Inputs/SliderInput.vue";
import FileInput from "@/components/Inputs/FileInput.vue";
import CommonTransition from "@/calculator/components/CommonComponents/Transition/CommonTransition.vue";
import DateInput from "@/calculator/components/CommonComponents/Inputs/DateInput.vue";
import CheckboxInput from "@/calculator/components/CommonComponents/Inputs/CheckboxInput.vue";
import DatePicker from "@/calculator/components/CommonComponents/Inputs/DatePicker.vue";
import Spinner from "@/components/Spinner/Spinner.vue";
import InfoBtn from "@/calculator/components/CommonComponents/InfoBtn/InfoBtn.vue";
import DefaultModal from "@/calculator/components/CommonComponents/Modals/DefaultModal.vue";
Vue.component("SelectInput", SelectInput);
Vue.component("MainBtn", MainBtn);
Vue.component("BtnWithShadow", BtnWithShadow);
Vue.component("TextInput", TextInput);
Vue.component("CommonTransition", CommonTransition);
Vue.component("DateInput", DateInput);
Vue.component("CheckboxInput", CheckboxInput);
Vue.component("SliderInput", SliderInput);
Vue.component("FileInput", FileInput);
// @ts-ignore
Vue.component("DatePicker", DatePicker);
Vue.component("MainSpinner", Spinner);
Vue.component("InfoBtn", InfoBtn);
Vue.component("DefaultModal", DefaultModal);
