export const mutations = {
    writeStatisticList(state, data) {
        state.statistics = [
            {
                id: 1,
                name: "Цель кредита",
                color: "#0097CE",
                values: data.creditType,
            },
            {
                id: 2,
                name: "Тип занятости",
                color: "#00B23D",
                values: data.employmentType,
            },
            {
                id: 3,
                name: "Подтип объекта",
                color: "#00CAC0",
                values: data.objectSubtype,
            },
        ];
    },
    writeGeneratingReport(state, data) {
        state.dataForGeneratingReport = [
            {
                name: data.averageObjectPrice[0].name,
                value: data.averageObjectPrice[0].value,
                img: require("public/assets/statistic/object-cost.svg"),
            },
            {
                name: data.averageInitialFee[0].name,
                value: data.averageInitialFee[0].value,
                img: require("public/assets/statistic/initial-fee.svg"),
            },
            {
                name: data.averageCreditSum[0].name,
                value: data.averageCreditSum[0].value,
                img: require("public/assets/statistic/amount-of-credit.svg"),
            },
            {
                name: data.averageCreditPeriod[0].name,
                value: data.averageCreditPeriod[0].value,
                img: require("public/assets/statistic/loan-terms.svg"),
            },
        ];
    },
    writeSelectionsCount(state, data) {
        state.selectionsCount = data.selectionsCount;
    },
    writeNewsList(state, data) {
        state.newsList = data;
    },
    writeChartData(state, data) {
        state.chartData = data;
        state.chartData.unshift(["", "Количество изменений"], ["", 0]);
    },
    writeFilteredChartData(state, data) {
        state.filteredChartData = data;
        state.filteredChartData.unshift(["", "Количество изменений"], ["", 0]);
    },
    writeBankRatingList(state, data) {
        state.bankRatingList = data;
    },
    writeBanksCount(state, data) {
        state.banksCount = data;
    },
};
