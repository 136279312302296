import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import i18n from "./i18n";
import store from "./store/app";
import vuetify from "./plugins/vuetify";
import VueSocialSharing from "vue-social-sharing";
import VueMeta from "vue-meta";
import ToggleButton from "vue-js-toggle-button";
import ErrorHandlerMixin from "@/mixins/common/ErrorHandler.vue";
import "@/mixins/globalСomponents";
Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_IS_DEV === "true";
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.use(ToggleButton);
Vue.mixin(ErrorHandlerMixin);
new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
