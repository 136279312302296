import Vue from "vue";
import Vuetify from "vuetify/lib";
if (process.env.VUE_APP_IS_WIDGET === "true") {
  import("./my-material-design-icons.scss");
} else {
  import("material-design-icons-iconfont/dist/material-design-icons.css");
}
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        mainColor: "#008ece",
      },
    },
  },
});
