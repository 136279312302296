export default class News {
    id;
    newsType;
    createdAt;
    updatedAt;
    date;
    link;
    likes;
    media;
    title;
    text;
    textFull;
    alias;
    youtubeLink;
    constructor(data) {
        /** id новости */
        this.id = data.id;
        /** Тип новости */
        this.newsType = data.news_event_type;
        /** Дата создания новости */
        this.createdAt = data.created_at;
        /** Дата обновления новости */
        this.updatedAt = data.updated_at;
        /** Отображаемое значение даты */
        this.date = data.date;
        /** Ссылка в новости */
        this.link = data.link;
        /** Массив лайков */
        this.likes = data.likes;
        /** Картинки новости */
        this.media = data.media;
        /** Название новости */
        this.title = data.title;
        /** Текст новости */
        this.text = data.text;
        /** Полный текст новости */
        this.textFull = data.text_full;
        /** ЧПУ новости */
        this.alias = data.alias;
        /** Ссылка на видео новости */
        this.youtubeLink = data.youtube_link;
    }
}
