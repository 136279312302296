import { AdditionalParametersCategory } from "@/calculator/shared/Classes/AdditionalParametersCategory";
import { ParameterForm } from "@/calculator/shared/Classes/ParameterForm";

export class AdditionalParametersCategoryForm extends AdditionalParametersCategory {
  /**
   * @param {object|AdditionalParametersCategory} data
   */
  constructor(data) {
    super(data);
  }

  updateParameters(parameters) {
    this.parameters = parameters
      .filter((parameter) => !parameter.hidden)
      .map((parameter) => new ParameterForm(parameter));
  }

  updateSubCategories(categories) {
    this.subCategories = categories
      .filter((category) => !category.hidden)
      .map((category) => new AdditionalParametersCategoryForm(category));
  }
}
