import Vue from "vue";
import Vuex from "vuex";
import { commonState } from "./state";
import { commonActions } from "./actions";
import { commonMutations } from "./mutations";
import { commonGetters } from "./getters";
Vue.use(Vuex);
export const common = {
    namespaced: true,
    state: commonState,
    actions: commonActions,
    mutations: commonMutations,
    getters: commonGetters,
};
