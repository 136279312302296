import store from "@/store/app";
import storeWidget from "@/store/widget";
import { getDefaultUserState } from "@/store/modules/user/state";
import { calcState, getDefaultCalcState, } from "@/store/modules/calculator/state";
import { getDefaultOrderState } from "@/store/modules/order/state";
import { getDefaultAccountState } from "@/store/modules/account/state";
import { getDefaultChecksState } from "@/store/modules/checks/state";
import { getDefaultStatisticState } from "@/store/modules/statistic/state";
import { commonState, getDefaultRootCommonState, } from "@/store/modules/common/state";
import { getDefaultWidgetState } from "@/store/widget/state";
export const ClearStateAndStorage = () => {
    window.localStorage.removeItem("User-token");
    window.localStorage.removeItem("basket");
    if (process.env.VUE_APP_IS_WIDGET === "true") {
        const state = getDefaultWidgetState();
        storeWidget.replaceState({
            ...state,
            calculator: {
                ...getDefaultCalcState(),
                banksLogos: calcState.banksLogos,
                bankAdvertises: calcState.bankAdvertises,
            },
            checks: getDefaultChecksState(),
            statistic: getDefaultStatisticState(),
            common: {
                ...getDefaultRootCommonState(),
                isRu: commonState.isRu,
            },
        });
        storeWidget.state.common.window = window.innerWidth;
    }
    else {
        store.replaceState({
            ...store.state,
            user: getDefaultUserState(),
            calculator: {
                ...getDefaultCalcState(),
                banksLogos: calcState.banksLogos,
                bankAdvertises: calcState.bankAdvertises,
            },
            order: getDefaultOrderState(),
            account: getDefaultAccountState(),
            checks: getDefaultChecksState(),
            statistic: getDefaultStatisticState(),
            common: {
                ...getDefaultRootCommonState(),
                isRu: commonState.isRu,
            },
        });
        store.state.common.window = window.innerWidth;
    }
};
export const ClearUserState = () => {
    window.localStorage.removeItem("User-token");
    window.localStorage.removeItem("basket");
    if (process.env.VUE_APP_IS_WIDGET === "true") {
        const state = getDefaultWidgetState();
        storeWidget.replaceState({
            ...state,
            checks: getDefaultChecksState(),
        });
    }
    else {
        store.replaceState({
            ...store.state,
            user: getDefaultUserState(),
            account: getDefaultAccountState(),
            checks: getDefaultChecksState(),
        });
    }
};
