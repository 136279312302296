export default function promoCalculation(
  baseRate, // Базовая ставка (Например 12.9)
  list, // Список всех акций для банка
  usedManualPromos = [], // Список выбранных ручных акций (Например [1276, 1277])
  currentAppliedPromo = 0, // Текущая выбранная акция, 0 - если ЗП проект
  salaryProjectDiscount = 0 // Величина скидки ЗП проекта. Если ЗП проект не выбран, тогда 0 (Например 0.4)
) {
  const formattedList = formatList(
    list,
    usedManualPromos,
    currentAppliedPromo,
    salaryProjectDiscount
  );
  const newList = formattedList.list;
  const error = formattedList.error;
  const result = baseRate - run(baseRate, newList, 0);
  return { promosList: newList, rate: result.toFixed(2), error: error }; // rate - Величина новой ставки
}

function run(baseRate, list, excludeId) {
  let always = 0;
  let variants = {};

  for (let key in list) {
    if (key != excludeId && hasExclude(list[key])) {
      variants[key] = list[key];
    } else {
      // если это не временная акция, то снижаем ставку
      if (!list[key].during_construction) {
        // 1 - скидки, 2 - фиксированная ставка
        let percent =
          list[key].type_id === 2
            ? baseRate - list[key].percent
            : list[key].percent;

        always += +percent;
      }
    }
  }

  return Math.max(...apply(always, exclude(baseRate, variants)));
}

function apply(n, list) {
  for (let i = 0; i < list.length; i++) {
    list[i] = list[i] + n;
  }
  list.push(n);
  return list;
}

function exclude(baseRate, list) {
  let result = [];
  for (let key in list) {
    let filtered = filter(list, list[key].incompatibilities);
    result.push(run(baseRate, filtered, list[key].id));
  }
  return result;
}

function filter(list, exclude) {
  let result = {};
  for (let key in list) {
    if (!exclude.includes(key)) {
      result[key] = list[key];
    }
  }
  return result;
}

function formatList(
  source,
  usedManualPromos,
  currentAppliedPromo,
  salaryProjectDiscount
) {
  let list = {};
  let error = "";
  let salaryProjectBlocked = false;
  Object.assign(list, source);

  // Если акция несовместима с ЗП проектом, то не применяем ее (вызываем прошлый запрос)
  let checkPriorityResult = checkPriorityAccess(
    source,
    currentAppliedPromo,
    salaryProjectDiscount,
    usedManualPromos
  );
  if (checkPriorityResult) {
    let oldManualPromos = arrayExclude(usedManualPromos, currentAppliedPromo);

    let formattedList = formatList(
      source,
      oldManualPromos,
      null,
      currentAppliedPromo ? salaryProjectDiscount : 0
    );

    return {
      list: formattedList.list,
      error: checkPriorityResult,
    };
  }

  // Сбрасываем ЗП проект, если применена акция с более высоким приоритетом
  if (
    currentAppliedPromo &&
    salaryProjectDiscount &&
    source[currentAppliedPromo].priority === 1
  ) {
    salaryProjectDiscount = 0;
    error = "З/П проект отменен, так как выбранная акция несовместима с ним";
  }

  // Сбрасываем ручные акции с низким приоритетом, если применен ЗП проект
  if (currentAppliedPromo === 0) {
    let usedManualPromosAfterAbort = [];
    for (let usedManualPromo of usedManualPromos) {
      if (source[usedManualPromo].priority === -1) {
        error =
          "Некоторые акции были отменены, так как несовместимы с З/П проектом";
        continue;
      }
      usedManualPromosAfterAbort.push(usedManualPromo);
    }

    usedManualPromos = usedManualPromosAfterAbort;
  }

  // сначала выбираем нужную акцию с фиксированной ставкой
  let promosWithImmunity = getFixPromoWithImmunity(
    list,
    usedManualPromos,
    currentAppliedPromo,
    salaryProjectDiscount
  );

  // Если акции с фикс. ставкой есть, но нет подходящей, то выбранную не применяем
  // Отправляем запрос без новой акции
  if (!promosWithImmunity) {
    let oldManualPromos = [];
    let oldSalaryProjectDiscount = salaryProjectDiscount;
    if (currentAppliedPromo) {
      oldManualPromos = arrayExclude(usedManualPromos, currentAppliedPromo);
    } else {
      oldManualPromos = usedManualPromos;
      oldSalaryProjectDiscount = 0;
    }
    let formattedList = formatList(
      source,
      oldManualPromos,
      null,
      oldSalaryProjectDiscount
    );

    return {
      list: formattedList.list,
      error: currentAppliedPromo
        ? "Акция не применена, так как нельзя отменить все акции с фиксированной ставкой;"
        : "З/П проект не применен, так как нельзя отменить все акции с фиксированной ставкой;",
    };
  }

  if (
    promosWithImmunity.length > 0 &&
    !list[promosWithImmunity[0]].salary_project
  ) {
    salaryProjectBlocked = true;
  }

  // Если выбраны ручные акции, убираем несовместимые
  // for (let manual of usedManualPromos) {
  usedManualPromos.forEach((manual) => {
    if (list[manual]) {
      for (let key in list[manual].incompatibilities) {
        if (
          list[list[manual].incompatibilities[key]] &&
          list[list[manual].incompatibilities[key]].id !== currentAppliedPromo
        ) {
          if (usedManualPromos.includes(+list[manual].incompatibilities[key])) {
            error = "Некоторые акции несовместимы, поэтому они отменены;";
          } else if (list[list[manual].incompatibilities[key]].handler === 2) {
            error =
              "Некоторые автоматические акции несовместимы, поэтому они отменены;";
          }
          // Если идет попытка исключить акцию с иммунитетом, то удаляем акцию, несовместимую с исключаемой
          if (
            promosWithImmunity.includes(list[manual].incompatibilities[key])
          ) {
            delete list[manual];
          } else {
            delete list[list[manual].incompatibilities[key]];
          }
        }
      }
    }
  });

  for (let key in list) {
    // Если выбран ЗП проект, убираем из списка несовместимые
    if (
      salaryProjectDiscount &&
      !list[key]["salary_project"] &&
      !salaryProjectBlocked
    ) {
      delete list[key];
      if (usedManualPromos.includes(+key)) {
        error =
          "З/П проект несовместим с некоторыми из выбранных акций, поэтому они отменены;";
      }
      continue;
    } else if (salaryProjectDiscount && salaryProjectBlocked) {
      error =
        "З/П проект не применен, так как он несовместим с действующими акциями с фиксированной ставкой;";
    }

    if (list[key]["handler"] !== 2) {
      if (!usedManualPromos.includes(+key)) {
        delete list[key];
      }
    } else if (list[key]["type_id"] === 2) {
      // Убираем все акции с фикс. ставкой, кроме выбранной
      if (!promosWithImmunity.includes(list[key].id)) {
        delete list[key];
      }
    }
  }

  if (salaryProjectDiscount && !salaryProjectBlocked) {
    list["salaryProject"] = {
      id: "salaryProject",
      percent: salaryProjectDiscount,
      incompatibilities: [],
    };
  }

  return { list: list, error: error };
}

function hasExclude(item) {
  return item.incompatibilities.length;
}

// Фиксированные акции имеют высокий приоритет, поэтому не могут быть отменены
// Но может быть выбрана другая, если их окажется несколько
function getFixPromoWithImmunity(
  list,
  usedManualPromos,
  currentAppliedPromo,
  salaryProjectDiscount
) {
  // Если выбрана ручная акция с фиксированной ставкой, то применяем ее
  if (
    usedManualPromos.includes(currentAppliedPromo) &&
    +list[currentAppliedPromo].type_id === 2
  ) {
    return [currentAppliedPromo];
  }

  // теперь проверяем, нет ли ручных акций c фиксированной ставкой, примененных ранее
  for (let key in usedManualPromos) {
    let promo = list[usedManualPromos[key]];
    if (+promo.type_id === 2 && +promo.handler === 1) {
      return [promo.id];
    }
  }

  let fixedPromos = [];

  // Сначала вытаскиваем все автоматические акции с фиксированной ставкой
  for (let key in list) {
    let promo = list[key];
    if (+promo.type_id === 2 && +promo.handler === 2) {
      fixedPromos.push(promo);
    }
  }

  // Затем сортируем их по величине ставки
  fixedPromos.sort((a, b) => a.percent - b.percent);

  // Если акций с фиксированной ставкой нет, то акций с иммунитетом не будет (1)
  // Должна остаться одна акция с иммунитетом и самой выгодной ставкой (2)
  // Если все фиксированные акции оказались несовместимы, то ручную акцию применять нельзя (3)
  if (fixedPromos.length) {
    let allIncompatibilities = [];
    usedManualPromos.forEach((promo) => {
      allIncompatibilities.push(...list[promo].incompatibilities);
    });

    for (let promo of fixedPromos) {
      if (currentAppliedPromo) {
        if (!allIncompatibilities.includes(String(promo.id))) {
          if (salaryProjectDiscount && !promo.salary_project) {
            continue;
          }
          return [promo.id]; // 2
        }
      } else {
        if (
          !allIncompatibilities.includes(String(promo.id)) &&
          ((+promo.salary_project === 1 && salaryProjectDiscount) ||
            !salaryProjectDiscount)
        ) {
          return [promo.id]; // 2
        }
      }
    }
    return null; // 3
  } else {
    return []; // 1
  }
}

function arrayExclude(array, exclude) {
  return [
    ...array.slice(0, array.indexOf(exclude)),
    ...array.slice(array.indexOf(exclude) + 1),
  ];
}

function checkPriorityAccess(
  promoList,
  usedPromo,
  salaryProject,
  usedManualPromos
) {
  // Это сценарий отмены применения акции, в таком случае конфликта нет
  if (usedPromo === null) {
    return "";
  }

  // Использовано промо
  if (usedPromo) {
    // Приоритет у З/П проекта
    if (promoList[usedPromo].priority === -1 && salaryProject) {
      return "Акция не применена, так как несовместима с З/П проектом";
    }
    // Использован З/П проект
  } else {
    for (let key in promoList) {
      if (usedManualPromos.indexOf(+key) === -1) {
        continue;
      }

      // Приоритет у акции
      if (promoList[key].priority === 1) {
        return "З/П проект не применен, так как несовместим с одной из выбранных акций";
      }
    }
  }
  return "";
}
