<template>
  <div>
    <v-btn
      class="button"
      :color="color"
      :height="height"
      :width="width"
      @click="$emit('click')"
      :disabled="disabled"
      :class="[
        { gold: colorClass === 'gold' },
        { blue: colorClass === 'blue' },
      ]"
      :loading="loading"
      :href="link"
    >
      <div class="button-text" :style="{ width: textWidth }">
        <v-icon v-if="icon">{{ icon }} </v-icon>
        {{ text }}
      </div>
    </v-btn>
    <div
      class="shadow"
      :class="[{ isDisabled: disabled }, { gold: colorClass === 'gold' }]"
    />
  </div>
</template>

<script>
export default {
  name: "BtnWithShadow",
  props: {
    text: {
      type: String,
      default: "кнопка",
    },
    icon: {
      type: String,
    },
    height: {
      type: Number,
      default: 48,
    },
    width: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    colorClass: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    link: {
      type: String,
    },
    textWidth: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.button {
  border-radius: 5px;
  padding: 0 16px;
  background-color: #fff !important;
  color: $mainColor !important;
  font-size: 16px !important;
  width: 100% !important;
  font-weight: 700;
  border: 1px solid $mainColor !important;
  z-index: 1;

  &:hover:enabled {
    background-color: $mainColor !important;
    color: #fff !important;
  }

  &.gold {
    color: #d59402 !important;
    font-size: 14px !important;
    border: 1px solid #d59402 !important;
    padding: 0 2px;

    &:hover:enabled {
      background-color: #d59402 !important;
    }
  }
  &.blue {
    color: #fff !important;
    background-color: $mainColor !important;

    &:hover:enabled {
      background-color: #fff !important;
      color: $mainColor !important;
      border: 1px solid $mainColor !important;
    }
  }

  &-text {
    @include center();
    gap: 5px;
    white-space: pre-wrap;
  }
}
.shadow {
  background-color: #007eb0;
  margin-top: -5px;
  border-radius: 5px;
  height: 7px;
  width: 100%;
  top: 1px;
  left: 0;
  z-index: 0;

  &.isDisabled {
    background-color: $dopColor;
  }
  &.gold {
    background-color: #c98a03;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  background-color: #fff !important;
  border: 1px solid $dopColor !important;
  color: $dopColor !important;
}
</style>
