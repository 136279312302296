<template>
  <v-text-field
    class="rounded-lg"
    outlined
    dense
    :label="label"
    :rules="rules"
    type="date"
    v-model="date"
    @input="save"
  />
</template>

<script>
export default {
  name: "DateInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      date: undefined,
    };
  },

  methods: {
    save() {
      this.$emit("input", this.date);
    },
  },

  watch: {
    value() {
      this.date = this.value;
    },
  },
};
</script>

<style scoped></style>
