import { calcState } from "./state";
import { calcMutations } from "./mutations";
import calcActions from "./actions";
import { calcGetters } from "./getters";
export const calculator = {
    namespaced: true,
    state: calcState,
    mutations: calcMutations,
    actions: calcActions,
    getters: calcGetters,
};
