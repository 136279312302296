export class Promotion {
  constructor(data) {
    this.id = data.id;
    this["bank_id"] = data["bank_id"];
    this["type_id"] = data["type_id"]; // 1 - скидка, 2 - фиксированная ставка
    this.name = data.name;
    this.description = data.description;
    this.percent = data.percent;
    this.incompatibilities = data.incompatibilities; // массив несовместимых акций
    this.priority = data.priority;
    this.applied = false;
    this.handler = data.handler; //у ручных акций 1
    this.salary_project = data.salary_project;
    this.during_construction = data.during_construction;
    this.construction_time = data.construction_time;
  }
}

export const promoTypeAndPriority = {
  /** Константа, которая указывает что данная акция делает скидку */
  discount: 1,
  /** Константа, которая указывает что данная акция устанавливает фиксированную ставку */
  fixRate: 2,
  /** Константа, которая указывает, что приоритет акции и зарплатного проекта равен */
  promoPriorityEqualSPPriority: 0,
};
