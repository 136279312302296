export const getters = {
    getMarketplacesTabs(state) {
        let markets = state.selectedBanks.marketplaces;
        let infoForTabs = [];
        markets.forEach((market) => {
            if (market) {
                let { id, logo, name, description, title } = market;
                infoForTabs.push({ id, logo, name, description, title });
            }
        });
        return infoForTabs;
    },
    getEmployments(state) {
        let employments = [];
        for (let key in state.employmentTypes) {
            employments.push({ id: +key, name: state.employmentTypes[key] });
        }
        return employments;
    },
    getDataForOrderForm(state) {
        return state.choosedBanksFormInfo.map((bank) => {
            return {
                id: bank.id,
                name: bank.name,
                logo: bank.logo,
                fields: bank.fields,
                roadmap: bank.roadmap,
                managers: bank.managers,
                managersForContact: bank.contactManagers,
                organizationType: bank.organization_type,
                myContract: bank.myContract,
                myCommission: bank.myCommission,
                myPreferences: bank.myPreferences,
            };
        });
    },
    getDataForSubmitCard(state) {
        let data = [];
        data = state.choosedBanksFormInfo.map((bank) => {
            return {
                id: bank.id,
                name: bank.name,
                document: bank.document,
                managers: bank.managers,
                managersForContact: bank.contactManagers,
                comment: bank.comment,
                roadmap: bank.roadmap,
                myContract: bank.myContract,
                myCommission: bank.myCommission,
                myPreferences: bank.myPreferences,
            };
        });
        return data;
    },
};
