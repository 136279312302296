import { Parameter } from "@/calculator/shared/Classes/ParameterClass";

export class AdditionalParametersCategory {
  constructor(data) {
    this.id = data["id"];
    this.name = data["name"];
    this.type = data["type"];
    this.updateParameters(Object.values(data["parameters"] || {}));
    this.updateSubCategories(Object.values(data["subCategories"] || {}));
  }

  /**
   * @param {object|Parameter[]} data
   */
  updateParameters(data) {
    if (this.type === "subCategory") {
      this.parameters = {};
      data.forEach((item, i) => (this.parameters[i] = new Parameter(item)));
    }
  }

  /**
   * @param {object|AdditionalParametersCategory[]} data
   */
  updateSubCategories(data) {
    if (this.type === "category") {
      this.subCategories = {};
      data.forEach(
        (item) =>
          (this.subCategories[item.id] = new AdditionalParametersCategory(item))
      );
    }
  }

  get hidden() {
    return (
      this.getAllParameters().filter((parameter) => !parameter.hidden)
        .length === 0
    );
  }

  set hidden(value) {
    console.log(value);
  }

  getAllParameters() {
    let parameters = this.parameters ? Object.values(this.parameters) : [];

    if (this.subCategories) {
      Object.values(this.subCategories).forEach((subCategory) => {
        parameters = parameters.concat(subCategory.getAllParameters());
      });
    }
    return parameters;
  }
}
