import News from "./NewsClass";
export default class NewsInfo {
    currentPage;
    numberNews;
    numberPage;
    news;
    /** Конструктор объекта новостей
     * @constructor
     * @param data данные для конструктора
     */
    constructor(data) {
        /** Последняя загруженная страница новостей
         * @type {number}
         */
        this.currentPage = data.currentPage;
        /** Общее кол-во новостей
         * @type {number}
         */
        this.numberNews = data.numberNews;
        /** Общее кол-во страниц
         * @type {number}
         */
        this.numberPage = data.numberPage;
        /** Массив новостей
         * @type {array}
         */
        this.news = this.newsConstructor(data.news);
    }
    newsConstructor(news) {
        let newsArr = [];
        news.forEach((item) => {
            newsArr.push(new News(item));
        });
        return newsArr;
    }
}
