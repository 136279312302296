export class BankCabinet {
    bankCabinet;
    // id: number;
    // name: string;
    // link?: string;
    // logo: string;
    // preferences: string;
    // preferencesComment: string;
    // commission: string;
    // commissionPostfix: string;
    // commissionComment: string;
    // organizationType: string;
    // managers?: ManagersForContactType[];
    // myCommission: {
    //   maxValue: number;
    //   minValue: number;
    // };
    // myPreferences: {
    //   value: number;
    // };
    constructor(data) {
        this.bankCabinet = {
            id: data.id,
            name: data.name,
            link: data?.pa_link,
            logo: data.logo,
            preferences: data.bankPreferences,
            preferencesComment: data.bankPreferencesComment,
            commission: data.bankCommission,
            commissionPostfix: data.bankCommissionPostfix,
            commissionComment: data.bankCommissionComment,
            organizationType: "",
            managers: data.managers,
            myCommission: data.myCommission,
            myPreferences: data.myPreferences,
            mainId: data.main_id,
        };
    }
}
export class Marketplace {
    id;
    name;
    link;
    logo;
    description;
    title;
    banks;
    mainId;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.link = data.url;
        this.logo = data.logo;
        this.description = data.description.description ?? "";
        this.title = data.description.title ?? "";
        this.banks = {};
        this.mainId = data.main_id;
        this.writeBanks(data.banks);
        this.setMarketplaceName(this.name);
    }
    writeBanks(data) {
        data.forEach((item) => (this.banks[item.id] = new MarketplaceBank(item)));
    }
    setMarketplaceName(name) {
        Object.values(this.banks).forEach((bank) => (bank.marketplaceName = name));
    }
}
class MarketplaceBank {
    id;
    name;
    logo;
    preferences;
    preferencesComment;
    commission;
    commissionComment;
    organizationType;
    marketplaceName;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.logo = data.logo;
        this.preferences = data.preferences;
        this.preferencesComment = data.comment_preferences;
        this.commission = data.commission;
        this.commissionComment = data.comment_commission;
        this.organizationType = "";
        this.marketplaceName = "";
    }
}
export class BankOrderForm {
    id;
    name;
    logo;
    comment;
    document;
    managers;
    fields;
    roadmap;
    organization_type;
    contactManagers;
    myContract;
    myCommission;
    myPreferences;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.logo = data.logo;
        this.comment = data.comment;
        this.document = data.document;
        this.managers = data.managers;
        this.fields = data.fields;
        this.roadmap = data.roadmap;
        this.organization_type = "";
        this.contactManagers = data.contactManagers ?? [];
        this.myContract = data.myContract;
        this.myCommission = data.myCommission;
        this.myPreferences = data.myPreferences;
    }
}
