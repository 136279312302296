import api from "@/shared/services/api";
export default async function getInsuranceInfo(data) {
    return api
        .post(process.env.VUE_APP_INSURANCE_TWO_CALCULATE, data)
        .then((res) => {
        if (res.data.status === "success") {
            return res.data.data;
        }
    })
        .catch(() => {
        return "error";
    });
}
