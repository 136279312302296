<template>
  <v-text-field
    class="border-radius"
    dense
    outlined
    :aria-autocomplete="offAutocomplete ? 'off' : 'on'"
    :hide-details="hideDetails"
    :label="label"
    :value="value"
    :type="type"
    :rules="rules"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    @input="updateValue($event)"
    @blur="$emit('blurInput')"
  />
</template>

<script>
export default {
  name: "TextInput",
  props: {
    /** Значение поля, обязательные параметр */
    value: {
      required: true,
    },
    /** label поля */
    label: {
      type: String,
    },
    max: {
      type: Number,
    },
    hideDetails: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "text",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
    },
    offAutocomplete: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },

  methods: {
    /** Обновляет значение поля
     * @param val актуальное значение
     */
    updateValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style scoped></style>
