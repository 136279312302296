import { parameterTypes } from "@/calculator/shared/Classes/ParameterClass";

export class CategoryExist {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.inputs = data.parameters.map((input) => new CategoryInput(input));
  }
}

export class CategoryNew extends CategoryExist {
  constructor(data) {
    super(data);
    this.inputs = data.parameters.map((input) => new newCategoryInput(input));
  }
}

class CategoryInput {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.techName = data["tech_name"] ?? data.techName;
    this.categoryId = data["category_id"] ?? data.categoryId;
    this.categoryName = data["category_name"] ?? data.categoryName;
    this.valueType = data["value_type"] ?? data.valueType;
    this.required = data.required;
    this.multiple = data.multiple;
    this.question = data.question;
    this.alarm = data.alarm;
    this.showPercent = data.showPercent;
    this.impactsFinder = data["parameterImpactsFinder"] ?? data.impactsFinder;
    this.min = data.min;
    this.max = data.max;
    this.values = data.values;
  }
}

export class newCategoryInput extends CategoryInput {
  constructor(data) {
    super(data);
    this.value = undefined;
    this.visible = true;
    this.changed = false;
  }
}
