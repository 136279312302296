<template>
  <div>
    <Transition name="fade">
      <slot></slot>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "CommonTransition",
  props: {
    transitionDuration: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
