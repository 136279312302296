import createId from "@/shared/scripts/createId";
export const mutations = {
    writeAllServices(state, data) {
        state.allServices = data ?? [];
    },
    writeAllReports(state, data) {
        state.allReports = data;
    },
    updateReports(state, data) {
        state.allReports = state.allReports.map((report) => {
            return data.find((el) => el.requestId === report.requestId) ?? report;
        });
    },
    addCheckToBasket(state, check) {
        state.basket.push({
            ...check,
            uniqueId: createId(state.basket.map((el) => el.uniqueId)),
        });
    },
    deleteBasketCheck(state, uniqueId) {
        state.basket = state.basket.filter((el) => el.uniqueId !== uniqueId);
    },
    clearBasket(state) {
        state.basket = [];
    },
    getBasketFromLocalStorage(state) {
        if (localStorage.getItem("basket")) {
            state.basket = JSON.parse(localStorage.getItem("basket"));
        }
        else {
            state.basket = [];
        }
    },
};
