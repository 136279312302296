import api from "@/shared/services/api";
import { getStore } from "@/store";
const store = getStore();
export default async function getUserInfo() {
    store.commit("calculator/toggleLoadingModules", true);
    return api
        .get(process.env.VUE_APP_USER_INFO, { withCredentials: true })
        .then((res) => {
        if (res.data.status === "success") {
            if (process.env["VUE_APP_IS_WIDGET"] === "false" ||
                !res.data.data.isFree) {
                sendTimeZone();
            }
            let data = { ...res.data.data };
            if (data.templates) {
                store.commit("calculator/writeTemplates", data.templates);
            }
            if (process.env["VUE_APP_IS_WIDGET"] === "false") {
                data["photo"] =
                    process.env.VUE_APP_BASE_URL + `/backend/${res.data.data["photo"]}`;
                store.commit("calculator/writeAvailableModules", data.accesses.access);
                return data;
            }
            store.commit("calculator/writeFakeAvailableModules");
            return data;
        }
    })
        .catch((e) => {
        return Promise.reject("error");
    })
        .finally(() => {
        store.commit("calculator/toggleLoadingModules", false);
    });
}
export async function sendTimeZone() {
    const timeZone = new Date().getTimezoneOffset() / 60;
    return api.post(process.env.VUE_APP_PROFILE_SET_TIMEZONE, {
        hours_timezone_offset_utc: timeZone,
    });
}
