import api from "@/shared/services/api";
import ErrorHandler from "@/shared/scripts/ErrorHandler";
export default async function buyBonusIndividual(sum) {
    return await api
        .post(process.env["VUE_APP_BONUS_TOP_UP"], { sum })
        .then((res) => {
        let response = res.data.data;
        if (response.type === "redirect") {
            window.open(response.value, "_blank").focus();
        }
        return "success";
    })
        .catch((err) => {
        let error = err.response.data;
        return ErrorHandler(error);
    });
}
