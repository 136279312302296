import api from "@/shared/services/api";
import NewsType from "@/components/MainPageComponents/MainNews/NewsTypeClass";
import NewsInfo from "@/components/MainPageComponents/MainNews/NewsInfoClass";
export const actions = {
    openAlertError({ commit }, e, errorMessageProp = "Внутренняя ошибка сервера") {
        /** Ошибка валидации не обрабатывается */
        if (e?.response?.data?.validationErrors?.length) {
            return;
        }
        const errorMessage = e?.response?.data?.errorMessage ?? errorMessageProp;
        if (errorMessage === "Выполните вход в систему") {
            return;
        }
        commit("calculator/openAlert", errorMessage, { root: true });
    },
    getNewsTypes({ dispatch }) {
        return api
            .get(process.env.VUE_APP_NEWS_TYPE_URL)
            .then((res) => [
            new NewsType({
                type: "",
                name: "Все новости",
                color: "#008ece",
            }),
            ...res.data.data.map((item) => new NewsType(item)),
        ])
            .catch((e) => dispatch("openAlertError", e));
    },
    getNewsByType({ dispatch }, options) {
        return api
            .get(`${process.env.VUE_APP_NEWS_URL}?page=${options.page}&newsEventType=${options.type}`)
            .then((res) => new NewsInfo(res.data.data))
            .catch((e) => dispatch("openAlertError", e));
    },
    async getNewsCount() {
        return api
            .get(`${process.env.VUE_APP_NEWS_COUNT}`)
            .then((res) => {
            return Promise.resolve(res.data.data);
        })
            .catch((e) => {
            return Promise.reject(e.response.data);
        });
    },
    sendOrderFormRequest({ dispatch, commit }, data) {
        return api
            .post(process.env.VUE_APP_ORDER_SEND_FORM_INFO, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(() => {
            commit("order/writeDocumentCommonError", "", { root: true });
            return "success";
        })
            .catch((e) => {
            const validationErrors = e?.response?.data?.validationErrors;
            if (validationErrors?.length) {
                const fileError = validationErrors.find((errors) => errors.field === "files");
                if (fileError) {
                    commit("order/writeDocumentCommonError", fileError.error, {
                        root: true,
                    });
                }
            }
            dispatch("openAlertError", e);
        });
    },
    getNews(_, alias) {
        return api
            .get(`${process.env.VUE_APP_NEWS_ID_URL}?alias=${alias}`)
            .catch((e) => {
            const { data } = e.response;
            if (data.code === 12) {
                window.location.href = "https://new.banki.shop/backend/error/404";
            }
        });
    },
    getInfluence({ commit }) {
        return api.get(process.env.VUE_APP_INFLUENCE_GET).then((data) => {
            commit("writeInfluence", data.data.data);
        });
    },
    checkPromocode(_, promocode) {
        return api.post(process.env.VUE_APP_PROMO_CODE, { code: promocode });
    },
    bankRating({ commit, dispatch }) {
        return api
            .get(process.env.VUE_APP_BANK_TRANSITIONS_STATISTIC_RATING)
            .then((res) => {
            commit("statistic/writeBankRatingList", res.data.data);
        })
            .catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
    supportSend({ commit, dispatch }, payload) {
        return api
            .post(process.env.VUE_APP_SUPPORT_SEND, payload)
            .then(() => {
            commit("calculator/openAlert", "Запрос удачно отправлен");
        })
            .catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
    faqList({ dispatch }) {
        return api.get(process.env.VUE_APP_FAQ_LIST).catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
    faqItem({ dispatch }, id) {
        return api.get(`${process.env.VUE_APP_FAQ_READ}?id=${id}`).catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
    timepadInfo({ commit, dispatch }) {
        return api
            .get(process.env.VUE_APP_TIMEPAD_LIST)
            .then((data) => {
            commit("writeTimepadList", data?.data?.data.events);
        })
            .catch((e) => {
            dispatch("openAlertError", e);
            return Promise.reject(e);
        });
    },
    statisticSaveTransition(_, payload) {
        return api.post(process.env.VUE_APP_BANK_TRANSITIONS_STATISTIC_SAVE_TRANSITION, payload);
    },
    markNewsView({ commit }) {
        return api.get(process.env.VUE_APP_USER_ALERT_NEWS_VIEWED).then(() => {
            commit("user/updateNewsAlert");
        });
    },
    sendNewsPing() {
        return api.get(process.env.VUE_APP_NEWS_PING);
    },
    sendOrderPing() {
        return api.get(process.env.VUE_APP_ORDER_PING);
    },
};
