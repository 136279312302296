import { render, staticRenderFns } from "./AnswersModal.vue?vue&type=template&id=5d973d26&scoped=true"
import script from "./AnswersModal.vue?vue&type=script&lang=ts"
export * from "./AnswersModal.vue?vue&type=script&lang=ts"
import style0 from "./AnswersModal.vue?vue&type=style&index=0&id=5d973d26&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d973d26",
  null
  
)

export default component.exports