export default function getAvailableTime(timestamp) {
    const sec = timestamp;
    const min = sec / 60;
    const hour = min / 60;
    const diffDays = Math.round(hour / 24);
    const diffHours = Math.round(hour % 24);
    const diffMinutes = Math.round(min % 60);
    if (diffDays >= 1) {
        return { time: diffDays, type: "days" };
    }
    else if (diffHours >= 1) {
        return { time: diffHours, type: "hours" };
    }
    else if (Math.floor(min % 60) >= 10) {
        return { time: diffMinutes, type: "minutes" };
    }
    else if (sec >= 1) {
        return { time: sec, type: "seconds" };
    }
    else {
        return 0;
    }
}
