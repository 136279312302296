<template>
  <a v-if="link" class="text-decoration-none" :href="link" target="_blank">
    <v-btn
      icon
      outlined
      :height="size"
      :width="size"
      :color="color"
      @click="$emit('click')"
    >
      <span
        :style="{
          fontSize: `${+size - 4}px !important`,
        }"
      >
        ?
      </span>
    </v-btn>
  </a>
  <v-btn
    v-else
    icon
    outlined
    :height="size"
    :width="size"
    :color="color"
    @click="$emit('click')"
  >
    <span
      :style="{
        fontSize: `${+size - 4}px !important`,
      }"
    >
      ?
    </span>
  </v-btn>
</template>

<script>
export default {
  name: "InfoBtn",
  props: {
    link: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#2396ca",
    },
    size: {
      type: String,
      default: "20",
    },
  },
};
</script>

<style scoped lang="scss">
.btn__info {
  background-color: #fff;
  transition: 0.3s;

  &:hover {
    background-color: #e3f3fa;
  }

  &-question {
    text-decoration: none;
  }
}
</style>
