const codeError = {
    validError: 12,
};
export default function (error) {
    let errObj = {};
    if (error["validationErrors"] !== undefined &&
        error.code === codeError.validError) {
        error["validationErrors"].forEach((err) => {
            errObj[err["field"]] = err.error;
        });
    }
    if (error["errorMessage"] !== undefined &&
        error["validationErrors"] === undefined) {
        errObj["errorMessage"] = error["errorMessage"];
    }
    return errObj;
}
