export const getDefaultWidgetState = () => {
    return {
        chief: 0,
        login: "",
        bonuses: 0,
        isFree: 0,
        isCheck: 0,
        isBusiness: 0,
        licenseAgreement: false,
        settings: {
            hideAdditionalParams: undefined,
            hideBaseContacts: undefined,
            hideSelectedPrograms: undefined,
            hideChecks: undefined,
            hideDigest: undefined,
            hideFirstStage: undefined,
            hideSecondStage: undefined,
            hideThirdStage: undefined,
        },
        userToken: undefined,
        participants: [],
    };
};
export const state = getDefaultWidgetState();
