import store from "@/store/app";
const bc = {
  channel: undefined,
};

try {
  bc.channel = new BroadcastChannel("bankiShop");
} catch (e) {
  console.log(e);
}

export function SyncUserInTabs(data = {}) {
  if (bc.channel === undefined) {
    return;
  }
  bc.channel.addEventListener("message", (e) => {
    if (Object.values(e.data).length === 0) {
      store.commit("user/logoutUser");
    } else {
      store.commit("user/loginUser", e.data);
    }
  });

  bc.channel.postMessage(data);
}
