import { BankCabinet, BankOrderForm, Marketplace, } from "@/components/SendOrderComponents/BanksCard/SendOrderClasses";
import store from "@/store/app";
export const mutations = {
    clearChoosedBanks(state) {
        state.choosedBanks = [];
    },
    writeChoosedBanks(state, data) {
        data.forEach((id) => {
            if (state.choosedBanks.includes(id)) {
                let index = state.choosedBanks.indexOf(id);
                state.choosedBanks.splice(index, 1);
            }
            else {
                state.choosedBanks.push(id);
            }
        });
    },
    toggleShowOrderForm(state, data) {
        state.showOrderForm = data;
    },
    toggleShowCompareForm(state, data) {
        state.showCompareForm = data;
    },
    toggleShowArchiveOrders(state, data) {
        state.showArchiveOrders = data;
    },
    setCompareBankId(state, id) {
        if (state.compareBankId.find((el) => el === id)) {
            let index = state.compareBankId.findIndex((el) => el === id);
            state.compareBankId.splice(index, 1);
        }
        else {
            state.compareBankId.push(id);
        }
        state.showCompareBtn = state.compareBankId.length > 0;
    },
    clearCompareBankId(state) {
        state.compareBankId = [];
        state.showCompareBtn = false;
    },
    writeChoosedMethod(state, data) {
        state.choosedMethod = data;
    },
    toggleLoader(state, bool) {
        state.showLoader = bool;
    },
    writeSelectedBanks(state, data) {
        state.selectedBanks[data.type] = [];
        data.response.forEach((item) => {
            if (data.type === "marketplaces") {
                const newMarketPlace = new Marketplace(item);
                for (const bank in newMarketPlace.banks) {
                    const bankItem = item.banks.find((el) => el.id === +bank);
                    newMarketPlace.banks[bank].organizationType =
                        store.getters["calculator/getTypesLoan"][bankItem?.organization_type];
                }
                state.selectedBanks["marketplaces"].push(newMarketPlace);
            }
            else if (["banks", "bankishop"].includes(data.type)) {
                const newBank = new BankCabinet(item);
                newBank.bankCabinet.organizationType =
                    store.getters["calculator/getTypesLoan"][item.organization_type];
                state.selectedBanks[data.type].push(newBank.bankCabinet);
            }
        });
    },
    clearSelectedBanks(state, type) {
        state.selectedBanks[type] = [];
    },
    writeEmployment(state, data) {
        state.employmentTypes = data;
    },
    writeEmploymentFromCalc(state, data) {
        state.employmentFromCalc = data;
    },
    writeBanksInfo(state, data) {
        state.choosedBanksFormInfo = [];
        data.forEach((item) => {
            const newBankOrderForm = { ...new BankOrderForm(item) };
            newBankOrderForm.organization_type =
                store.getters["calculator/getTypesLoan"][item.organization_type];
            if (newBankOrderForm) {
                state.choosedBanksFormInfo.push(newBankOrderForm);
            }
        });
    },
    writeOtherFields(state, data) {
        state.otherFields = data;
    },
    writeRegionFromCalc(state, data) {
        state.choosedRegionFromCals = data;
    },
    writeDataForOrder(state, data) {
        if (data.region !== undefined) {
            state.regionForOrder = data.region;
        }
        if (data.employment !== undefined) {
            state.employmentForOrder = data.employment;
        }
    },
    writeRegionsAndEmployments(state, data) {
        state.regionList = data.regions;
        state.regionsForOrderList = data.regionsForOrder;
        state.employmentTypes = data.employmentTypes;
    },
    writeInfoFromDirectory(state, data) {
        state.infoFromDirectory = data;
        if (data.choosedBanks) {
            state.choosedBanks = JSON.parse(JSON.stringify(data.choosedBanks));
        }
        if (data.choosedMethod) {
            state.choosedMethod = data.choosedMethod;
        }
        state.switcherEnable = {
            bankishop: true,
            marketplaces: true,
            cabinet: true,
        };
        state.selectedBanks = {
            marketplaces: [],
            banks: [],
            bankishop: [],
        };
        state.switcher = true;
    },
    clearInfoFromDirectory(state) {
        Object.keys(state.infoFromDirectory).map((key) => (state.infoFromDirectory[key] = undefined));
    },
    writeDocumentCommonError(state, data) {
        state.documentCommonError = data;
    },
    writeShowRewardUser(state, payload) {
        state.showRewardUser = payload;
    },
    writeShowPreferencesUser(state, payload) {
        state.showPreferencesUser = payload;
    },
    writeSwitcher(state, data) {
        state.switcher = data;
    },
    writeSwitcherEnable(state, data) {
        Object.keys(data).map((key) => {
            if (key in state.switcherEnable) {
                state.switcherEnable[key] =
                    data[key];
            }
        });
    },
    writeArchiveOrdersList(state, data) {
        state.archiveOrdersList = data.map((el) => {
            el.bank_id = +el.bank_id;
            return el;
        });
    },
};
