export const commonMutations = {
    /** Запись текущей ширины экрана
     * @param state хранилище
     * @param width передаваемое значение ширины
     */
    writeWindow(state, width) {
        state.window = width;
    },
    openInfoModal(state, data) {
        state.infoModal = { openModal: true, text: data.text, type: data.type };
    },
    closeInfoModal(state) {
        state.infoModal = {
            openModal: false,
            text: "",
            type: "",
        };
    },
    toggleShowUpdateInfoModal(state, bool) {
        state.showUpdateInfoModal = bool;
    },
    changeIsRu(state, data) {
        state.isRu = data;
    },
};
