export const getDefaultStatisticState = () => {
    return {
        statistics: [],
        dataForGeneratingReport: [],
        selectionsCount: 0,
        newsList: [],
        chartData: [],
        filteredChartData: [],
        bankRatingList: [],
        banksCount: 0,
    };
};
export const state = getDefaultStatisticState();
