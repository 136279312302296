export default function duringPromoCalc(list, //Список всех акций для банка
currentAppliedPromo //Текущая выбранная акция
) {
    let newPromoList = {};
    Object.entries(list).forEach((promo) => {
        if (+promo[0] === +currentAppliedPromo) {
            if (!promo[1].applied) {
                newPromoList[promo[0]] = list[promo[0]];
            }
        }
        else if (promo[1].applied) {
            newPromoList[promo[0]] = list[promo[0]];
        }
    });
    const excludePromoData = excludePromo(newPromoList, currentAppliedPromo);
    return {
        promosList: excludePromoData.promo,
        error: excludePromoData.error,
    };
}
function excludePromo(newPromoList, currentAppliedPromo) {
    let excludePromoList = { ...newPromoList };
    let error = "";
    let diffRate = 0;
    Object.entries(newPromoList).forEach((promo) => {
        if (promo[1].during_construction && promo[1].incompatibilities.length) {
            promo[1].incompatibilities.forEach((incompatibility) => {
                if (Object.keys(newPromoList).includes(incompatibility) &&
                    +incompatibility !== currentAppliedPromo) {
                    if (newPromoList[incompatibility].during_construction) {
                        error = "Некоторые акции несовместимы, поэтому они отменены;";
                        delete excludePromoList[incompatibility];
                    }
                }
            });
        }
    });
    return { promo: excludePromoList, error: error };
}
export function getDiffRate(promos, incompatibilities) {
    let diffRate = 0;
    Object.entries(promos).forEach((promo) => {
        if (promo[1].applied && incompatibilities.includes(promo[0])) {
            diffRate += +promo[1].percent;
        }
    });
    return diffRate;
}
