export const getDefaultOrderState = () => {
    return {
        choosedBanks: [],
        choosedBanksFormInfo: [],
        otherFields: [],
        choosedRegionFromCals: undefined,
        showOrderForm: false,
        showCompareForm: false,
        showCompareBtn: false,
        compareBankId: [],
        showLoader: false,
        choosedMethod: "",
        regionList: [],
        regionsForOrderList: [],
        employmentTypes: {},
        employmentFromCalc: undefined,
        selectedBanks: {
            marketplaces: [],
            banks: [],
            bankishop: [],
        },
        regionForOrder: undefined,
        employmentForOrder: undefined,
        infoFromDirectory: {
            exist: false,
            regionId: 0,
            employmentType: 0,
            organizationType: 0,
            choosedBanks: [],
            choosedBanksMarketplace: [],
            choosedBanksNames: [],
            choosedMethod: "",
            switcherText: "",
            marketplaceFlag: false,
            partnersAccountFlag: false,
            bankishopFlag: false,
            infoFrom: {
                text: "",
                nameRoute: "",
            },
        },
        documentCommonError: "",
        switcher: true,
        switcherEnable: {
            bankishop: true,
            marketplaces: true,
            cabinet: true,
        },
        showRewardUser: false,
        showPreferencesUser: false,
        showArchiveOrders: false,
        archiveOrdersList: [],
    };
};
export const state = getDefaultOrderState();
