<template>
  <v-textarea
    :class="{
      'border-radius': !customStyles,
    }"
    dense
    outlined
    :error-messages="errorMessage"
    :label="label"
    :value="value"
    :rules="enableRules"
    :hide-details="hideDetails"
    :height="height"
    :disabled="disabled"
    @input="updateValue($event)"
    v-bind="settings"
    :auto-grow="autoGrow"
    :background-color="bgColor"
  />
</template>

<script>
import Vue from "vue";

/** Компонент textarea поля */
export default Vue.extend({
  name: "TextAreaInput",
  props: {
    /** Значение поля, обязательные параметр */
    value: {
      type: String,
      required: true,
    },
    /** Label поля */
    label: {
      type: String,
    },
    /** Задает цвет фона поля*/
    bgColor: {
      type: String,
    },
    height: {
      type: String,
      default: "auto",
    },
    /** Правила валидации */
    rules: {
      type: Array,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    customStyles: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /** Правила валидации */
      validateRules: {
        required: (v) => v !== "" || "Обязательное поле",
      },
      settings: {
        "no-resize": this.noResize,
      },
    };
  },

  methods: {
    /**
     * Обновляет значение поля
     * @param val актуальное значение
     */
    updateValue(val) {
      this.$emit("input", val);
    },
  },

  computed: {
    /**
     * Применение правил валидации к полю
     * @returns {*[]} возвращает массив функций
     */
    enableRules() {
      let rules = [];

      Object.keys(this.validateRules).forEach((rule) => {
        if (this.rules !== undefined && this.rules.includes(rule)) {
          rules.push(this.validateRules[rule]);
        }
      });

      return rules;
    },
  },
});
</script>

<style scoped></style>
