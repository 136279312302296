export const getters = {
    getUserFio(state) {
        if (state.user.surname || state.user.name || state.user.patronymic) {
            return `${state.user.surname} ${state.user.name} ${state.user.patronymic}`;
        }
        return "МЕНЮ";
    },
    getUserFioWithInitials(state) {
        if (state.user.surname || state.user.name || state.user.patronymic) {
            let firstLetterOfName = state.user.name
                ? `${state.user.name.substring(0, 1).toUpperCase()}.`
                : "";
            let firstLetterOfPatronymic = state.user.patronymic
                ? `${state.user.patronymic.substring(0, 1).toUpperCase()}.`
                : "";
            return `${state.user.surname} ${firstLetterOfName} ${firstLetterOfPatronymic}`;
        }
        return "МЕНЮ";
    },
    getUserLogged(state) {
        return state.userLogged;
    },
    getUserBonuses(state) {
        return state.user.bonuses;
    },
    getIsUserBusinessAccount(state) {
        return state.user.account ? state.user.account.tariff_id === "5" : false;
    },
};
