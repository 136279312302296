import api from "@/shared/services/api";
import store from "@/store/app";
export function changeCalcTemplateTitle(data) {
    let dataForModal;
    return api
        .post(process.env.VUE_APP_CALCULATOR_TEMPLATE_RENAME, data)
        .then((res) => {
        dataForModal = {
            status: res.data.status,
            successText: "Данные сохранены",
            isValidationErrors: false,
        };
        store.dispatch("common/openInfoModal", dataForModal);
        return res.data.status;
    })
        .catch((e) => {
        dataForModal = {
            status: "error",
            errorMessage: e?.response?.data?.errorMessage ?? "Данные не сохранены",
            isValidationErrors: e?.response?.data?.validationErrors?.length,
        };
        store.dispatch("common/openInfoModal", dataForModal);
        return e.response.data;
    });
}
export function deleteCalcTemplate(id) {
    let dataForModal;
    return api
        .post(process.env.VUE_APP_CALCULATOR_TEMPLATE_DELETE, { id: id })
        .then((res) => {
        dataForModal = {
            status: res.data.status,
            successText: "Шаблон удален",
            isValidationErrors: false,
        };
        store.dispatch("common/openInfoModal", dataForModal);
        return res.data.status;
    })
        .catch((e) => {
        dataForModal = {
            status: "error",
            errorMessage: e?.response?.data?.errorMessage ?? "Произошла ошибка",
            isValidationErrors: e?.response?.data?.validationErrors?.length,
        };
        store.dispatch("common/openInfoModal", dataForModal);
        return e.response.data;
    });
}
export function changeVisibilityCalcTemplate(url, data) {
    let dataForModal;
    return api
        .post(url, data)
        .then((res) => {
        dataForModal = {
            status: res.data.status,
            successText: "Изменения сохранены",
            isValidationErrors: false,
        };
        store.dispatch("common/openInfoModal", dataForModal);
        return res.data.status;
    })
        .catch((e) => {
        dataForModal = {
            status: "error",
            errorMessage: e?.response?.data?.errorMessage ?? "Произошла ошибка",
            isValidationErrors: e?.response?.data?.validationErrors?.length,
        };
        store.dispatch("common/openInfoModal", dataForModal);
        return e.response.data;
    });
}
export function sortCalcTemplates(ids) {
    return api
        .post(process.env.VUE_APP_CALCULATOR_TEMPLATE_SORT, ids)
        .catch((e) => {
        store.dispatch("openAlertError", e);
    });
}
export async function getGuestTemplates() {
    try {
        const res = await api.get(process.env.VUE_APP_PROFILE_GUEST);
        if (res.data.status === "success") {
            let data_1 = { ...res.data.data };
            store.commit("calculator/writeTemplates", data_1.templates);
            store.commit("calculator/writeGuestFakeAvailableModules");
        }
    }
    catch (e) {
        store.dispatch("openAlertError", e);
    }
}
