//import Cookies from "js-cookie";
import Vue from "vue";

window.flutter_run = function (event, data) {
  data = data ? data : {};

  if (flutter.isMobApp()) {
    switch (event) {
      case "alert": // показываем alert
        alert(data["text"]);
        break;

      case "menu": // показываем/скрываем меню
        Vue.component("Header").$refs.menu_icon.activate();
        alert(33);
        break;

      default:
        break;
    }
  }
};

var flutter = {
  isMobApp() {
    return typeof window.flutter_app_handler != "undefined";
  },

  sendMessage() {
    if (typeof window.flutter_app_handler != "undefined") {
      window.flutter_app_handler.postMessage(773);
    }
  },
};

export default flutter;
