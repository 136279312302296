export const mutations = {
    writeParticipantList(state, data) {
        state.participants = data ?? [];
        state.modules.participants = true;
    },
    updateParticipantLoading(state, bool) {
        state.participantsLoading = bool;
    },
    writeBankContact(state, data) {
        state.contactsBank = data ?? [];
        state.modules.contactsBank = true;
    },
    writeBankAgreements(state, data) {
        state.bankAgreements = data ?? [];
        state.modules.bankAgreements = true;
    },
    writeMyCommissions(state, data) {
        state.myCommissions = data ?? [];
        state.modules.myCommissions = true;
    },
    writeSections(state, data) {
        state.sections = data ?? [];
    },
};
