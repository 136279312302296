import api from "@/shared/services/api";
import { getStore } from "@/store";
const store = getStore();
export async function buyForEntity(data, url) {
    let sendData = new FormData();
    for (let key in data) {
        let name = key;
        let value = data[key] ?? "";
        sendData.append(name, value);
    }
    return api
        .post(url, sendData)
        .then((res) => {
        store.dispatch("common/saveFile", {
            content: res.data.data.content,
            fileName: res.data.data.name,
        });
        return { type: "fileSave" };
    })
        .catch((response) => {
        const error = response.response.data;
        const validationErrors = error.validationErrors;
        if (validationErrors?.length) {
            return { type: "error", value: validationErrors[0].error };
        }
        if (error.errorMessage?.length) {
            return { type: "error", value: error.errorMessage };
        }
        return { text: "Ошибка", color: "error" };
    });
}
